import { Callout } from '@blueprintjs/core';

import { Button, Card, Col, Popconfirm, Row, Spin } from 'antd';
import { createContext, FC, useEffect, useReducer, useState } from 'react';
import { connect } from 'react-redux';

import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.types';
import StateUpdateHook from '../../../../core/records/components/StateUpdateHook';
import {
  addRecordToShortList,
  IAddRecordToShortList,
} from '../../../../core/records/store/actions';
import {
  getRecordAssociationsRequest,
  getRecordAssociationWithNestedEntitiesRequest,
  IGetRecordAssociations,
  IGetRecordAssociationWithNestedEntities,
} from '../../../../core/recordsAssociations/store/actions';
import { getSchemaByModuleAndEntityRequest } from '../../../../core/schemas/store/actions';
import { httpGet, httpPost } from '../../../../shared/http/requests';
import { hasPermissions } from '../../../../shared/permissions/rbacRules';
import { displayMessage } from '../../../../shared/system/messages/store/reducers';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../shared/utilities/schemaHelpers';
import AddNetworkDeviceModal from './AddNetworkDeviceModal';
import DisabledNetworkPanelOverlay from './DisabledNetworkPanelOverlay';
import FullCheckDrawer from './FullCheckDrawer';
import {
  isOrderItemAddonProduct,
  isOrderItemBaseProduct,
  isOrderItemBatteryPack,
  isOrderItemStaticIP,
  isOrderItemVoiceProduct,
} from './helpers';
import { NetworkAddonBroadbandProduct } from './NetworkAddonBroadbandProduct';
import NetworkAddonStaticIpProduct from './NetworkAddonStaticIpProduct';
import { NetworkAddonVoiceProduct } from './NetworkAddonVoiceProduct';
import { NetworkBaseProduct } from './NetworkBaseProduct';
import NetworkDeviceDrawer from './NetworkDeviceDrawer';
import {
  ORDER_NETWORK_DEVICES_SET_IS_LOADING_ORDER_ITEMS,
  ORDER_NETWORK_DEVICES_SET_ORDER_ITEMS,
  SET_RUNNING_BULK_ONT_CHECK,
  SET_RUNNING_BULK_PROVISION,
  SET_RUNNING_BULK_ROUTER_SPEED_TEST,
} from './store/constants';
import {
  OrderNetworkDevicesInitialState,
  orderNetworkDevicesReducer,
} from './store/reducer';
import './styles.scss';

interface Props {
  record: DbRecordEntityTransform; // Order record
  workOrderRecord?: DbRecordEntityTransform; // Work Order record
  schemaReducer: any;
  getSchema: (params: any, cb?: any) => void;
  getAssociations: (params: IGetRecordAssociations, cb?: any) => void;
  getNestedAssociations: (
    params: IGetRecordAssociationWithNestedEntities,
    cb?: any,
  ) => void;
  alertMessage: (params: { body: string; type: string }) => void;
  disableCheckAll?: boolean;
  userReducer: any;
}

let timer: NodeJS.Timeout | undefined = undefined;
const interval = 4000;

const { ORDER_MODULE, SERVICE_MODULE } = SchemaModuleTypeEnums;
const { ORDER, ORDER_ITEM, CUSTOMER_DEVICE_ONT, CUSTOMER_DEVICE_ROUTER } =
  SchemaModuleEntityTypeEnums;

export const OrderNetworkDevicesContext = createContext<any>({});

const OrderNetworkItems: FC<Props> = (props: Props) => {
  const {
    disableCheckAll,
    record,
    schemaReducer,
    getSchema,
    getAssociations,
    getNestedAssociations,
    alertMessage,
    userReducer,
    workOrderRecord,
  } = props;

  const [checkDrawerOpen, setCheckDrawerOpen] = useState(false);
  const [orderSchema, setOrderSchema] = useState<SchemaEntity | undefined>(
    undefined,
  );
  const [state, dispatch] = useReducer(
    orderNetworkDevicesReducer,
    OrderNetworkDevicesInitialState,
  );
  const [clearingBngSessions, setClearingBngSessions] = useState(false);

  const orderItems = state.orderItems;

  const setOrderNetworkDevicesOrderItems = (
    payload: DbRecordEntityTransform[],
  ) => {
    dispatch({ type: ORDER_NETWORK_DEVICES_SET_ORDER_ITEMS, payload });
  };

  const setOrderNetworkDevicesIsLoadingOrderItems = (payload: boolean) => {
    dispatch({
      type: ORDER_NETWORK_DEVICES_SET_IS_LOADING_ORDER_ITEMS,
      payload,
    });
  };

  const setBulkRunningONTCheck = (payload: boolean) => {
    dispatch({ type: SET_RUNNING_BULK_ONT_CHECK, payload });
  };

  const setBulkRunningProvision = (payload: boolean) => {
    dispatch({ type: SET_RUNNING_BULK_PROVISION, payload });
  };

  const setBulkRunningRouterSpeedTest = (payload: boolean) => {
    dispatch({ type: SET_RUNNING_BULK_ROUTER_SPEED_TEST, payload });
  };

  // Configure polling timer
  const startTimer = () => {
    timer = setInterval(() => {
      if (!document.hidden) {
        getAllOrderItems();
      }
    }, interval);
  };
  const clearTimer = () => {
    clearInterval(timer);
    timer = undefined;
  };

  // Start timer on component mount, clear timer on unmount
  useEffect(() => {
    if (orderSchema && !timer) {
      startTimer();
    }
  }, [orderSchema]);
  useEffect(() => {
    return () => {
      clearTimer();
    };
  }, []);

  const recordChanged = () => {
    clearTimer();
    startTimer();
    getAllOrderItems();
  };

  // On component mount, fetch Order schema
  useEffect(() => {
    const shortlistSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      ORDER_MODULE,
      ORDER,
    );

    if (shortlistSchema) {
      setOrderSchema(shortlistSchema);
    } else {
      getSchema(
        { moduleName: ORDER_MODULE, entityName: ORDER },
        (responseSchema: SchemaEntity) => {
          if (responseSchema) {
            setOrderSchema(responseSchema);
          }
        },
      );
    }
  }, []);

  // On component mount, fetch ONT schema
  useEffect(() => {
    const shortlistSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      SERVICE_MODULE,
      CUSTOMER_DEVICE_ONT,
    );

    if (!shortlistSchema) {
      getSchema({
        moduleName: SERVICE_MODULE,
        entityName: CUSTOMER_DEVICE_ONT,
      });
    }
  }, []);

  // When Schema is available, fetch order items
  useEffect(() => {
    if (record) {
      setOrderNetworkDevicesIsLoadingOrderItems(true);
      getAllOrderItems();
    }
  }, [record]);

  const getAllOrderItems = () => {
    if (record) {
      httpGet(
        `OrderModule/v1.0/db-associations/Order/${record.id}/one-relation?entity=OrderItem&nestedEntities=["CustomerDeviceRouter", "CustomerDeviceOnt", "VoiceConfiguration", "CustomerDeviceAta"]&withLinks=false`,
      )
        .then((res) => {
          setOrderNetworkDevicesIsLoadingOrderItems(false);
          setOrderNetworkDevicesOrderItems(
            res.data['OrderItem']?.dbRecords || [],
          );
        })
        .catch((err) => {
          setOrderNetworkDevicesIsLoadingOrderItems(false);
          console.error('Error loading tab data:', err);
        });
    }
  };

  const shouldDisableNetworkPanel = () => {
    if (hasPermissions(userReducer, 'fieldservicemodule.admin')) {
      return false;
    } else if (
      !hasPermissions(userReducer, 'fieldservicemodule.admin') &&
      workOrderRecord &&
      ![
        'WorkOrderStageInProgress',
        'BusinessInstallPipelineStageInProgress',
      ].includes(String(workOrderRecord?.stage?.key))
    ) {
      return true;
    } else {
      return false;
    }
  };

  const renderOrderItem = (orderItem: DbRecordEntityTransform) => {
    // BASE PRODUCT
    if (isOrderItemBaseProduct(orderItem)) {
      return (
        <NetworkBaseProduct
          record={orderItem}
          onUpdated={getAllOrderItems}
          staticIpRecord={orderItems.find((x: DbRecordEntityTransform) =>
            isOrderItemStaticIP(x),
          )}
        />
      );
    }
    // VOICE ADDON PRODUCT
    else if (
      isOrderItemAddonProduct(orderItem) &&
      isOrderItemVoiceProduct(orderItem) &&
      !isOrderItemBatteryPack(orderItem)
    ) {
      return (
        <NetworkAddonVoiceProduct
          record={orderItem}
          onUpdated={getAllOrderItems}
          orderRecord={record}
        />
      );
    }
    // BROADBAND ADDON PRODUCT (but not Static IP or Battery Pack)
    else if (
      isOrderItemAddonProduct(orderItem) &&
      !isOrderItemVoiceProduct(orderItem) &&
      !isOrderItemStaticIP(orderItem) &&
      !isOrderItemBatteryPack(orderItem)
    ) {
      return (
        <NetworkAddonBroadbandProduct
          record={orderItem}
          onUpdated={getAllOrderItems}
        />
      );
    } else if (
      isOrderItemAddonProduct(orderItem) &&
      isOrderItemStaticIP(orderItem)
    ) {
      return (
        <NetworkAddonStaticIpProduct record={orderItem} orderRecord={record} />
      );
    } else {
      return <Callout title="Nothing to action" intent="success" />;
    }
  };

  const renderOrderItems = () => {
    let filteredOrderItems = Object.assign(orderItems);
    filteredOrderItems.sort(
      (a: DbRecordEntityTransform, b: DbRecordEntityTransform) => {
        if (isOrderItemBaseProduct(a) && !isOrderItemBaseProduct(b)) {
          return -1;
        } else if (!isOrderItemBaseProduct(a) && isOrderItemBaseProduct(b)) {
          return 1;
        } else {
          return 0;
        }
      },
    );

    if (filteredOrderItems.length > 0) {
      return filteredOrderItems.map(
        (orderItem: DbRecordEntityTransform, i: number) => (
          <Col
            span={24}
            key={orderItem.id}
            style={{ marginTop: i === 0 ? 0 : 15 }}
          >
            <Card title={orderItem.title} size="small">
              {shouldDisableNetworkPanel() ? (
                <DisabledNetworkPanelOverlay>
                  {renderOrderItem(orderItem)}
                </DisabledNetworkPanelOverlay>
              ) : (
                <>{renderOrderItem(orderItem)}</>
              )}
            </Card>
          </Col>
        ),
      );
    }
  };

  const showFullCheckDrawer = () => {
    setCheckDrawerOpen(true);
  };

  const provisionAll = async () => {
    if (orderItems.length > 0) {
      alertMessage({
        body: `Provisioning all services not already provisioned, please wait this can take a while.`,
        type: 'success',
      });

      try {
        setBulkRunningProvision(true);

        await httpPost(
          `ServiceModule/v2.0/network/onu/all/${orderItems[0].id}/activate`,
          {},
        );

        setTimeout(() => {
          setBulkRunningProvision(false);
        }, 4000);
      } catch (err) {
        setBulkRunningProvision(false);
      }
    }
  };

  const clearBngSessions = async () => {
    try {
      setClearingBngSessions(true);

      const res = await httpPost(
        `ServiceModule/v1.0/network/bng/clear/${record.id}`,
        {},
      );
      const sessions =
        (res?.data?.data?.deletedDataSessions?.length || 0) +
          (res?.data?.data?.deletedVoiceSessions?.length || 0) || 0;

      if (sessions === 0) {
        alertMessage({
          body: `There are no sessions to clear!`,
          type: 'info',
        });
      } else {
        alertMessage({
          body: `Cleared ${sessions} BNG session(s)`,
          type: 'success',
        });
      }

      setClearingBngSessions(false);
    } catch (err: any) {
      alertMessage({
        body:
          err?.response?.data?.message ||
          err?.message ||
          'Failed to clear BNG sessions',
        type: 'error',
      });

      setClearingBngSessions(false);
    }
  };

  return (
    <OrderNetworkDevicesContext.Provider value={{ state, dispatch }}>
      <NetworkDeviceDrawer />

      {record && (
        <FullCheckDrawer
          open={checkDrawerOpen}
          onClose={() => setCheckDrawerOpen(false)}
          record={record}
        />
      )}

      <AddNetworkDeviceModal onUpdate={getAllOrderItems} />
      <StateUpdateHook trigger={record} onChange={recordChanged} />
      <Row>
        {!disableCheckAll && (
          <Col span={24} style={{ textAlign: 'right', marginTop: 5 }}>
            <Popconfirm
              title="Are you sure you want to clear all BNG sessions for this order?"
              onConfirm={clearBngSessions}
              okText="Yes"
              cancelText="No"
              disabled={clearingBngSessions}
            >
              <Button
                style={{ marginRight: 10 }}
                loading={clearingBngSessions}
                disabled={clearingBngSessions || shouldDisableNetworkPanel()}
              >
                Clear BNG Sessions
              </Button>
            </Popconfirm>
            <Button
              onClick={provisionAll}
              loading={state.runningBulkONTCheck}
              disabled={
                state.runningBulkONTCheck || shouldDisableNetworkPanel()
              }
              style={{ marginRight: 10 }}
            >
              Provision All
            </Button>
            <Button
              onClick={showFullCheckDrawer}
              loading={state.runningBulkONTCheck}
              disabled={
                state.runningBulkONTCheck || shouldDisableNetworkPanel()
              }
            >
              Check All Devices
            </Button>
          </Col>
        )}
        <Col span={24} style={{ marginBottom: 20 }}>
          {/* <Divider style={{ marginTop: 15, marginBottom: 20 }} /> */}
        </Col>
        {state.isLoadingOrderItems && (
          <Col span={24} style={{ textAlign: 'center', padding: '60px 10px' }}>
            <Spin size="large" style={{ marginBottom: 20 }} />
            <br />
            <span>Loading...</span>
          </Col>
        )}
        {!state.isLoadingOrderItems && orderItems.length > 0 && (
          <Col span={24}>
            <Row>{renderOrderItems()}</Row>
          </Col>
        )}
      </Row>
    </OrderNetworkDevicesContext.Provider>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) =>
    dispatch(displayMessage(params)),
  getSchema: (params: any, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(params, cb)),
  shortListRecord: (params: IAddRecordToShortList) =>
    dispatch(addRecordToShortList(params)),
  getAssociations: (params: IGetRecordAssociations, cb: any) =>
    dispatch(getRecordAssociationsRequest(params, cb)),
  getNestedAssociations: (
    params: IGetRecordAssociationWithNestedEntities,
    cb: any,
  ) => dispatch(getRecordAssociationWithNestedEntitiesRequest(params, cb)),
});

export default connect(mapState, mapDispatch)(OrderNetworkItems);
